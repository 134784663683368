@import "../../../styles/shreddit-variables";

.optionsContainer {
  width: 100vw;
  z-index: 10;
}

.menuToggle {
  border: 1px solid $inlay-fret-color;
}

.menuToggle:hover {
  cursor: pointer;
}

.menuOption {
  transition: all 0.1s ease-in-out;
}

.menuOption:hover {
  cursor: pointer;
  background-color: $fret-color;
  border-radius: 5px;
  box-shadow: 0 0 5px $inlay-fret-color;
  transform: scale(1.1);
}

@media screen and (min-width: 992px) {
  .optionsContainer {
    max-width: 100%;
  }
}
