@import "../../../styles/shreddit-variables";

.neck {
  background-image: url("../../../../public/images/woodGrainBGMobile.png");
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 10px;
  border: 2px solid $custom-dark;
}

.stringContainer {
  position: relative;
  overflow: hidden;
}

.stringContainer::before {
  content: "";
  height: 96%;
  width: 96%;

  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 992px) and (orientation: landscape) {
  .neck {
    background-image: url("../../../../public/images/woodGrainBGDesktop.png");
  }
}

@media screen and (min-width: 992px) {
  .stringContainer::before {
    width: 100%;
  }

  .neck {
    background-image: url("../../../../public/images/woodGrainBGDesktop.png");
  }
}
