@import "../../../styles/shreddit-variables";

.settingsMenuToggle {
  transition: all 0.1s ease-in-out;
  z-index: 12;
}
.settingsMenuToggle:hover {
  cursor: pointer;
  color: $custom-info;
}
