.container {
  height: 100%;
  width: 100%;
}

.sliderContainer {
  height: 100%;
  width: 100%;
}

.slider {
  width: 100%;
}

.sliderTrack,
.sliderRange {
  height: 10px;
}

.sliderTrack {
  z-index: 3;
}

.sliderRange {
  background-color: #eaeaea;
  border-radius: 5px;
  width: 100%;
  z-index: 2;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  background-color: red;
  height: 0px;
  width: 100%;
  top: 50%;
  outline: none;
}

.thumbIndex4 {
  z-index: 4;
}

.thumbIndex5 {
  z-index: 5;
}

.thumbIndex6 {
  z-index: 6;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.leftLabel {
  margin-top: 40px;
  left: 6px;
}

.rightLabel {
  margin-top: 40px;
  right: -6px;
}
