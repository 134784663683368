@import "styles/shreddit-variables";
// @import "../../styles/shreddit-variables";

.settingsMenuContainer {
  z-index: 15;
}

.settingsMenu {
  z-index: 16;
}

.xIcon {
  transition: all 0.1s ease-in-out;
}

.xIcon:hover {
  cursor: pointer;
  color: $custom-info;
}
