$P1-color: #eeeb2c;
$m2-color: #ec9828;
$M2-color: #d95628;
$m3-color: #d3263a;
$M3-color: #ca1574;
$P4-color: #75193e;
$A4-color: #5c205b;
$d5-color: #5c205b;
$P5-color: #312d5c;
$A5-color: #1c72a5;
$m6-color: #1c72a5;
$M6-color: #0da5a6;
$m7-color: #0fa96d;
$M7-color: #67bb41;

$fret-color: #eaeaea;
$inlay-fret-color: #acacac;
$fret-border-color: #696969;

@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

// $custom-primary: #ff512a;
// $custom-secondary: #003049;
$custom-info: #2ad8ff;
$custom-light: #f2f2f2;
$custom-dark: #212529;
// $custom-overlay: #000000;
// $custom-disabled: #eaeaea;
// $custom-danger: #dc3545;

// $custom-green: hsl(110, 50%, 55%);
// $custom-yellow: hsl(60, 100%, 48%);
// $custom-red: rgb(255, 118, 118);

// Fonts
$text: "Didact Gothic", sans-serif;
$heading: "Roboto", sans-serif;

$custom-colors: (
  // add custom colors here
  // e.g. $purple: #800080;
  "fret": $fret-color,
  "inlay-fret": $inlay-fret-color,
  "fret-border": $fret-border-color,
  "P1": $P1-color,
  "m2": $m2-color,
  "M2": $M2-color,
  "m3": $m3-color,
  "M3": $M3-color,
  "P4": $P4-color,
  "A4": $A4-color,
  "d5": $d5-color,
  "P5": $P5-color,
  "A5": $A5-color,
  "m6": $m6-color,
  "M6": $M6-color,
  "A6": $m7-color,
  "m7": $m7-color,
  "M7": $M7-color,
  "P8": $P1-color
);
